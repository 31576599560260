export const FEATURE_ACHIEVEMENTS = 'achievements'
export const FEATURE_ACTIVITY_LOG = 'activity-log'
export const FEATURE_ACTIVITY_LOG_COMPANY = 'activity-log-company'
export const FEATURE_AD_AVERAGE_ACTIVITY_TIME_LP = 'ad-average-activity-time-lp'
export const FEATURE_AD_AVERAGE_COMPLETION_RESULT_METRIC =
  'ad-average-completion-result'
export const FEATURE_AD_AVERAGE_COURSE_RATING = 'ad-average-course-rating'
export const FEATURE_AD_AVERAGE_SCORE_POWER_SKILLS =
  'ad-average-score-power-skills'
export const FEATURE_AD_COMPLETED_COURSES = 'ad-completed-courses'
export const FEATURE_AD_COMPLETED_LESSONS = 'ad-completed-lessons'
export const FEATURE_AD_COURSE_STARTERS_COMPLETERS =
  'ad-courses-starters-completers'
export const FEATURE_AD_HIGHEST_LEVEL_POWER_SKILL =
  'ad-highest-level-power-skill'
export const FEATURE_AD_HIGHEST_LEVEL_POWER_SKILLS =
  'ad-highest-level-power-skills'
export const FEATURE_AD_LP_ACTIVITY_BY_TYPE = 'ad-lp-activity-by-type'
export const FEATURE_AD_LESSONS_RATINGS_TOTAL = 'ad-lessons-ratings-total'
export const FEATURE_AD_LICENSES = 'ad-licenses'
export const FEATURE_AD_LP_ELEMENTS_ACTIVITY_BY_TYPE =
  'ad-lp-elements-activity-by-type'
export const FEATURE_AD_LP_PROGRESS = 'ad-lp-progress'
export const FEATURE_AD_LP_STARTERS_COMPLETERS = 'ad-lp-starters-completers'
export const FEATURE_AD_MOST_POPULAR_COURSE = 'ad-most-popular-course'
export const FEATURE_AD_MOST_POPULAR_DOMAIN = 'ad-most-popular-domain'
export const FEATURE_AD_MOST_POPULAR_POWER_SKILL = 'ad-most-popular-power-skill'
export const FEATURE_AD_POWER_SKILL_MOST_POINTS_ACHIEVED =
  'ad-power-skill-most-points-achieved'
export const FEATURE_AD_COURSE_STARTS_COMPLETIONS =
  'ad-course-starts-completions'
export const FEATURE_AD_LESSONS_STARTS_COMPLETIONS =
  'ad-lessons-starts-completions'
export const FEATURE_AD_LESSONS_PROGRESS = 'ad-lessons-progress'
export const FEATURE_AD_TOTAL_LESSONS_COMPLETIONS =
  'ad-total-lessons-completions'
export const FEATURE_AD_LP_STARTS_COMPLETIONS = 'ad-lp-starts-completions'
export const FEATURE_AD_LP_ELEMENTS_STARTS_COMPLETIONS =
  'ad-lp-elements-starts-completions'
export const FEATURE_AD_TOP_10_POPULAR_SEARCHES = 'ad-top-10-popular-searches'
export const FEATURE_AD_TOP_5_COMPLETED_LESSONS = 'ad-top-5-completed-lessons'
export const FEATURE_AD_TOP_5_MOST_COMPLETED_COURSES =
  'ad-top-5-most-completed-courses'
export const FEATURE_AD_TOP_5_RATED_LESSONS = 'ad-top-5-rated-lessons'
export const FEATURE_AD_TOTAL_USERS_ACTIVITY_TIME =
  'ad-total-users-activity-time'
export const FEATURE_AD_TOTAL_USERS = 'ad-total-users'
export const FEATURE_AD_USERS = 'ad-users'
export const FEATURE_AD_USERS_COMPLETED_SKILL_ASSESSMENT =
  'ad-users-completed-skills-assessment'
export const FEATURE_AD_USERS_FINISHED_FUNDAMENTAL_COURSE =
  'ad-users-finished-fundamental-course'
export const FEATURE_AD_USERS_LEARNING_GROUPS = 'ad-users-learning-groups'
export const FEATURE_AD_USERS_MIN_1_USER_RATING = 'ad-users-min-1-user-rating'
export const FEATURE_AD_USERS_POWER_SKILLS = 'ad-users-power-skills'
export const FEATURE_AD_USERS_RATED_1_COURSE = 'ad-users-rated-1-course'
export const FEATURE_AI_COURSE_DASHBOARD_POD = 'ai-course-dashboard-pod'
export const FEATURE_ANALYTICS = 'analytics'
export const FEATURE_BATCH_INVITE = 'batch-invite'
export const FEATURE_CERTIFICATE = 'certificate'
export const FEATURE_COMPANY = 'company'
export const FEATURE_CONTENT_LANGUAGE_FLAGS = 'content-language-flags'
export const FEATURE_CORE_SKILLS_ASSESSMENT = 'core-skills-assessment'
export const FEATURE_COMBINED_ACCOUNT_AND_SETTINGS_PAGES =
  'combined-account-and-settings-pages'
export const FEATURE_CHANGING_USER_NAMES_AND_EMAIL =
  'changing-user-names-and-email'
export const FEATURE_COMPANY_LEARNING_WIDGETS = 'company-learning-widgets'
export const FEATURE_DASHBOARD_BEST_TOPICS_CHANNEL =
  'dashboard-best-topics-channel'
export const FEATURE_DASHBOARD_BOOKMARKED = 'dashboard-bookmarked-lectures'
export const FEATURE_DASHBOARD_CHANNELS = 'dashboard-channels'
export const FEATURE_DASHBOARD_COMPLETED = 'dashboard-completed'
export const FEATURE_DASHBOARD_COURSE = 'dashboard-course'
export const FEATURE_DASHBOARD_CURRENT_LECTURE = 'dashboard-current-lecture'
export const FEATURE_DASHBOARD_HIGHLIGHTED_TOPIC = 'dashboard-highlighted-topic'
export const FEATURE_DASHBOARD_INTERNAL_CHANNEL =
  'dashboard-internal-topics-channel'
export const FEATURE_DASHBOARD_LEARNPATH = 'dashboard-learnpaths'
export const FEATURE_DASHBOARD_LEARNPATHS_TILE_LAYOUT =
  'dashboard-learnpaths-tile-layout'
export const FEATURE_DASHBOARD_LEARNPATH_COMPANY =
  'dashboard-learnpaths-company'
export const FEATURE_DASHBOARD_NEW_TOPICS_CHANNEL =
  'dashboard-new-topics-channel'
export const FEATURE_DASHBOARD_USER_CHANNEL = 'dashboard-user-channel'
export const FEATURE_DISCOVER = 'discover'
export const FEATURE_DOWNLOAD_NOTES = 'download-notes'
export const FEATURE_EXPORT_CONTENT_AS_SCORM = 'export-content-as-scorm'
export const FEATURE_GLOSSARY = 'glossary'
export const FEATURE_GROWTH_MAP = 'growth-map'
export const FEATURE_KPI_DASHBOARD = 'kpi-dashboard'
export const FEATURE_LEARNGROUP = 'learngroup'
export const FEATURE_LEARNING_ELEMENTS_TRACKING = 'learning-elements-tracking'
export const FEATURE_LEARNPATH = 'learnpath'
export const FEATURE_LEARNPATH_AS_A_COURSE = 'learnpath-as-a-course'
export const FEATURE_LEARNPATH_BLENDED_LEARNING = 'learnpath-blended-learning'
export const FEATURE_LEARNPATH_CERTIFICATE_ISSUE = 'learnpath-certificate-issue'
export const FEATURE_LEARNPATH_ELEMENT_EDITION = 'learnpath-element-edition'
export const FEATURE_LEARNPATH_EMBEDDED_VIDEO = 'learnpath-embedded-video'
export const FEATURE_LEARNPATH_FILE_UPLOAD = 'learnpath-file-upload'
export const FEATURE_LEARNPATH_MANDATORY = 'learnpath-mandatory'
export const FEATURE_LEARNPATH_MANDATORY_REMINDERS =
  'learnpath-mandatory-reminders'
export const FEATURE_LEARNPATH_MASTERPLAN_CONTENT =
  'learnpath-masterplan-content'
export const FEATURE_LEARNPATH_ONDEMAND_ELEMENTS = 'learnpath-ondemand-elements'
export const FEATURE_LEARNPATH_OWNER = 'learnpath-owner'
export const FEATURE_LEARNPATH_PDF_PREVIEW = 'learnpath-pdf-preview'
export const FEATURE_LEARNPATH_POINTS = 'learnpath-points'
export const FEATURE_LEARNPATH_QUIZ = 'learnpath-quiz'
export const FEATURE_LEARNPATH_QUIZ_MULTIPLE_CHOICE =
  'learnpath-quiz-multiple-choice'
export const FEATURE_LEARNPATH_QUIZ_THRESHOLD = 'learnpath-quiz-threshold'
export const FEATURE_LEARNPATH_SCORM = 'learnpath-scorm'
export const FEATURE_LEARNPATH_STATS_DEFAULT_COMPANY_ADMIN =
  'learnpath-stats-default-company_admin'
export const FEATURE_LEARNPATH_STATS_DEFAULT_MANAGER =
  'learnpath-stats-default-manager'
export const FEATURE_LEARNPATH_STATS_DEFAULT_OWNER =
  'learnpath-stats-default-owner'
export const FEATURE_LEARNPATH_STATS_MANDATORY_COMPANY_ADMIN =
  'learnpath-stats-mandatory-company_admin'
export const FEATURE_LEARNPATH_STATS_MANDATORY_MANAGER =
  'learnpath-stats-mandatory-manager'
export const FEATURE_LEARNPATH_STATS_MANDATORY_OWNER =
  'learnpath-stats-mandatory-owner'
export const FEATURE_LEARNPATH_THUMBNAILS = 'learnpath-thumbnails'
export const FEATURE_LEARNPATH_USER_GROUPS = 'learnpath-user-groups'
export const FEATURE_LEARNPATH_USER_GROUPS_PROGRESS_TAB =
  'learnpath-user-groups-progress-tab'
export const FEATURE_LEARNPATH_VIDEO_UPLOAD = 'learnpath-video-upload'
export const FEATURE_LEARNPATH_WITHOUT_DUE_DATE = 'learnpath-without-due-date'
export const FEATURE_LEARNPATH_RESTRICTED_ACCESS = 'learnpath-restricted-access'
export const FEATURE_LINK_SHARING = 'link-sharing'
export const FEATURE_MANAGER_LEARNPATH_VIEW = 'manager-learnpath-view'
export const FEATURE_MONTHLY_CHALLENGE = 'monthly-challenge'
export const FEATURE_NOTIFICATIONS = 'notifications'
export const FEATURE_NO_EMAIL_USERS = 'no-email-users'
export const FEATURE_SIGNUP_AVATAR = 'signup-avatar'
export const FEATURE_SIGNUP_DATE_OF_BIRTH = 'signup-date-of-birth'
export const FEATURE_SIGNUP_EMAIL_REQUIRED = 'signup-email-required'
export const FEATURE_SIGNUP_FIELD_OF_ACTIVITY = 'signup-field-of-activity'
export const FEATURE_SIGNUP_GENDER = 'signup-gender'
export const FEATURE_SIGNUP_POLL = 'signup-poll'
export const FEATURE_SIGNUP_PRIVACY_POLICY = 'signup-privacy-policy'
export const FEATURE_SIGNUP_PRIVACY_POLICY_VOLKSWAGEN =
  'signup-privacy-policy-volkswagen'
export const FEATURE_SIGNUP_PROFILE = 'signup-profile'
export const FEATURE_SOCIAL_AUTH = 'social-auth'
export const FEATURE_UNIVERSAL_MEDIA_PLAYER = 'universal-media-player'
export const FEATURE_UNIVERSAL_MEDIA_PLAYER_WISTIA =
  'universal-media-player-wistia'
export const FEATURE_USERPILOT = 'userpilot'
export const FEATURE_USER_GROUPS = 'user-groups'
export const FEATURE_USER_NOTES = 'user-notes'
export const FEATURE_USER_DEACTIVATION = 'user-deactivation'
export const FEATURE_USER_DELETION = 'user-deletion'
export const FEATURE_VIDEO_PLAYER_THUMBNAILS = 'video-player-thumbnails'
export const FEATURE_SNOW = 'snow'
export const FEATURE_LECTURE_PAGE_DARK_MODE = 'lecture-page-dark-mode'
export const FEATURE_UNIFIED_GENERIC_COURSE_VIEW = 'unified-generic-course-view'
export const FEATURE_DEV_TOOLS = 'dev-tools-panel'

export const FEATURE_FLAGS = [
  FEATURE_ACHIEVEMENTS,
  FEATURE_ACTIVITY_LOG,
  FEATURE_ACTIVITY_LOG_COMPANY,
  FEATURE_AD_AVERAGE_ACTIVITY_TIME_LP,
  FEATURE_AD_AVERAGE_COMPLETION_RESULT_METRIC,
  FEATURE_AD_AVERAGE_COURSE_RATING,
  FEATURE_AD_AVERAGE_SCORE_POWER_SKILLS,
  FEATURE_AD_COMPLETED_COURSES,
  FEATURE_AD_COMPLETED_LESSONS,
  FEATURE_AD_COURSE_STARTERS_COMPLETERS,
  FEATURE_AD_HIGHEST_LEVEL_POWER_SKILL,
  FEATURE_AD_HIGHEST_LEVEL_POWER_SKILLS,
  FEATURE_AD_LP_ACTIVITY_BY_TYPE,
  FEATURE_AD_LESSONS_RATINGS_TOTAL,
  FEATURE_AD_LICENSES,
  FEATURE_AD_LP_ELEMENTS_ACTIVITY_BY_TYPE,
  FEATURE_AD_LP_PROGRESS,
  FEATURE_AD_LP_STARTERS_COMPLETERS,
  FEATURE_AD_MOST_POPULAR_COURSE,
  FEATURE_AD_MOST_POPULAR_DOMAIN,
  FEATURE_AD_MOST_POPULAR_POWER_SKILL,
  FEATURE_AD_POWER_SKILL_MOST_POINTS_ACHIEVED,
  FEATURE_AD_COURSE_STARTS_COMPLETIONS,
  FEATURE_AD_LESSONS_STARTS_COMPLETIONS,
  FEATURE_AD_LP_STARTS_COMPLETIONS,
  FEATURE_AD_LP_ELEMENTS_STARTS_COMPLETIONS,
  FEATURE_AD_TOP_10_POPULAR_SEARCHES,
  FEATURE_AD_TOP_5_COMPLETED_LESSONS,
  FEATURE_AD_TOP_5_MOST_COMPLETED_COURSES,
  FEATURE_AD_TOP_5_RATED_LESSONS,
  FEATURE_AD_TOTAL_USERS_ACTIVITY_TIME,
  FEATURE_AD_TOTAL_USERS,
  FEATURE_AD_USERS,
  FEATURE_AD_USERS_COMPLETED_SKILL_ASSESSMENT,
  FEATURE_AD_USERS_FINISHED_FUNDAMENTAL_COURSE,
  FEATURE_AD_USERS_LEARNING_GROUPS,
  FEATURE_AD_USERS_MIN_1_USER_RATING,
  FEATURE_AD_USERS_POWER_SKILLS,
  FEATURE_AD_USERS_RATED_1_COURSE,
  FEATURE_AI_COURSE_DASHBOARD_POD,
  FEATURE_ANALYTICS,
  FEATURE_BATCH_INVITE,
  FEATURE_CERTIFICATE,
  FEATURE_COMPANY,
  FEATURE_CONTENT_LANGUAGE_FLAGS,
  FEATURE_CORE_SKILLS_ASSESSMENT,
  FEATURE_DASHBOARD_BEST_TOPICS_CHANNEL,
  FEATURE_DASHBOARD_BOOKMARKED,
  FEATURE_DASHBOARD_CHANNELS,
  FEATURE_DASHBOARD_COMPLETED,
  FEATURE_DASHBOARD_COURSE,
  FEATURE_DASHBOARD_CURRENT_LECTURE,
  FEATURE_DASHBOARD_HIGHLIGHTED_TOPIC,
  FEATURE_DASHBOARD_INTERNAL_CHANNEL,
  FEATURE_DASHBOARD_LEARNPATH,
  FEATURE_DASHBOARD_LEARNPATHS_TILE_LAYOUT,
  FEATURE_DASHBOARD_LEARNPATH_COMPANY,
  FEATURE_DASHBOARD_NEW_TOPICS_CHANNEL,
  FEATURE_DASHBOARD_USER_CHANNEL,
  FEATURE_DISCOVER,
  FEATURE_DOWNLOAD_NOTES,
  FEATURE_EXPORT_CONTENT_AS_SCORM,
  FEATURE_GLOSSARY,
  FEATURE_GROWTH_MAP,
  FEATURE_KPI_DASHBOARD,
  FEATURE_LEARNGROUP,
  FEATURE_LEARNING_ELEMENTS_TRACKING,
  FEATURE_LEARNPATH,
  FEATURE_LEARNPATH_AS_A_COURSE,
  FEATURE_LEARNPATH_BLENDED_LEARNING,
  FEATURE_LEARNPATH_CERTIFICATE_ISSUE,
  FEATURE_LEARNPATH_ELEMENT_EDITION,
  FEATURE_LEARNPATH_EMBEDDED_VIDEO,
  FEATURE_LEARNPATH_FILE_UPLOAD,
  FEATURE_LEARNPATH_MANDATORY,
  FEATURE_LEARNPATH_MANDATORY_REMINDERS,
  FEATURE_LEARNPATH_MASTERPLAN_CONTENT,
  FEATURE_LEARNPATH_ONDEMAND_ELEMENTS,
  FEATURE_LEARNPATH_OWNER,
  FEATURE_LEARNPATH_PDF_PREVIEW,
  FEATURE_LEARNPATH_POINTS,
  FEATURE_LEARNPATH_QUIZ,
  FEATURE_LEARNPATH_QUIZ_MULTIPLE_CHOICE,
  FEATURE_LEARNPATH_QUIZ_THRESHOLD,
  FEATURE_LEARNPATH_SCORM,
  FEATURE_LEARNPATH_STATS_DEFAULT_COMPANY_ADMIN,
  FEATURE_LEARNPATH_STATS_DEFAULT_MANAGER,
  FEATURE_LEARNPATH_STATS_DEFAULT_OWNER,
  FEATURE_LEARNPATH_STATS_MANDATORY_COMPANY_ADMIN,
  FEATURE_LEARNPATH_STATS_MANDATORY_MANAGER,
  FEATURE_LEARNPATH_STATS_MANDATORY_OWNER,
  FEATURE_LEARNPATH_THUMBNAILS,
  FEATURE_LEARNPATH_USER_GROUPS,
  FEATURE_LEARNPATH_USER_GROUPS_PROGRESS_TAB,
  FEATURE_LEARNPATH_VIDEO_UPLOAD,
  FEATURE_LEARNPATH_WITHOUT_DUE_DATE,
  FEATURE_LINK_SHARING,
  FEATURE_MONTHLY_CHALLENGE,
  FEATURE_NOTIFICATIONS,
  FEATURE_NO_EMAIL_USERS,
  FEATURE_SIGNUP_AVATAR,
  FEATURE_SIGNUP_DATE_OF_BIRTH,
  FEATURE_SIGNUP_EMAIL_REQUIRED,
  FEATURE_SIGNUP_FIELD_OF_ACTIVITY,
  FEATURE_SIGNUP_GENDER,
  FEATURE_SIGNUP_POLL,
  FEATURE_SIGNUP_PRIVACY_POLICY,
  FEATURE_SIGNUP_PRIVACY_POLICY_VOLKSWAGEN,
  FEATURE_SIGNUP_PROFILE,
  FEATURE_SOCIAL_AUTH,
  FEATURE_UNIVERSAL_MEDIA_PLAYER,
  FEATURE_USERPILOT,
  FEATURE_USER_GROUPS,
  FEATURE_USER_NOTES,
  FEATURE_VIDEO_PLAYER_THUMBNAILS,
  FEATURE_SNOW,
  FEATURE_LECTURE_PAGE_DARK_MODE,
]
