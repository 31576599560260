import * as Sentry from '@sentry/react'
import { ReactQueryDevtoolsPanel } from '@tanstack/react-query-devtools'
import { useContext, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useSessionStorage } from 'react-use'

import { useContentLock } from '@/app/student/hooks/useContentLock'
import { env } from '@/env'

import { FEATURE_DEV_TOOLS } from '../constants/features'
import { FeatureContext } from '../utils/features/FeatureContext'
import useFeatures from '../utils/features/useFeatures'

function FeatureFlags() {
  const features = useContext(FeatureContext)
  const [searchQuery, setSearchQuery] = useState('')

  const filteredFeatures =
    features?.filter((key) =>
      key.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || []

  return (
    <div>
      <h5
        style={{
          fontSize: '16px',
          margin: '12px 0 8px 0',
          borderBottom: '1px solid #555',
        }}>
        Feature Flags
      </h5>
      <div style={{ marginBottom: '8px' }}>
        <input
          type="text"
          placeholder="Search features..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: '100%',
            padding: '4px 8px',
            borderRadius: '4px',
            border: '1px solid #555',
            background: '#333',
            color: 'white',
          }}
        />
      </div>
      <ol style={{ margin: 0, paddingLeft: '20px' }}>
        {filteredFeatures.map((flagName, index) => (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={`${flagName}-${index}`}
            style={{
              margin: '4px 0',
            }}>
            <span style={{ color: 'yellow' }}>{flagName}</span>
          </li>
        ))}
      </ol>
    </div>
  )
}

function getSessionReplayLink() {
  const replay = Sentry.getReplay()
  if (replay) {
    replay.flush()
    const replayId = replay.getReplayId()
    return `https://masterplan-com-gmbh.sentry.io/replays/${replayId}/`
  }
  return ''
}

function SessionReplay() {
  const sentryLink = getSessionReplayLink()

  return (
    <div>
      <h5
        style={{
          fontSize: '16px',
          margin: '12px 0 8px 0',
          borderBottom: '1px solid #555',
        }}>
        Session Replay
      </h5>
      <div style={{ marginBottom: '8px' }}>
        <ul>
          <li>
            <div>Sentry:</div>
            {sentryLink ? (
              <>
                <a
                  href={sentryLink}
                  target="_blank"
                  style={{ color: '#cfc7ff', textDecoration: 'underline' }}
                  rel="noopener noreferrer">
                  {getSessionReplayLink()}
                </a>
                🡕
              </>
            ) : (
              <div style={{ color: 'yellow' }}>
                Current session is not being recorded
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  )
}

function ContentLock() {
  const { lockContent } = useContentLock()
  return (
    <div>
      <h5
        style={{
          fontSize: '16px',
          margin: '12px 0 8px 0',
          borderBottom: '1px solid #555',
        }}>
        Lock Content
      </h5>
      <div>
        Status:{' '}
        <span style={{ color: 'yellow' }}>{lockContent ? 'On' : 'Off'}</span>
      </div>
      <div>
        <button
          style={{
            background: 'none',
            border: '1px solid #fff',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '8px',
          }}
          type="button"
          onClick={() => {
            if (!lockContent) {
              const url = new URL(window.location.href)
              url.searchParams.set('lock_content', 'true')
              window.location.href = url.toString()
            } else {
              sessionStorage.removeItem('lock_content')
              const url = new URL(window.location.href)
              url.searchParams.delete('lock_content')
              window.location.href = url.toString()
            }
          }}>
          {lockContent ? 'Deactivate Content Lock' : 'Activate Content Lock'}
        </button>
      </div>
    </div>
  )
}

export function DevToolsPanel() {
  const hasDevTools = useFeatures(FEATURE_DEV_TOOLS)
  const [isAppDevToolsOpen, setIsAppDevToolsOpen] = useSessionStorage(
    'devtools-visible',
    false,
  )
  const [isReactQueryDevToolsOpen, setIsReactQueryDevToolsOpen] =
    useState(false)

  if (!hasDevTools) {
    return null
  }

  return (
    <>
      {env.MODE === 'development' && (
        <button
          type="button"
          onClick={() => setIsReactQueryDevToolsOpen(!isReactQueryDevToolsOpen)}
          style={{
            position: 'fixed',
            bottom: '10px',
            right: '150px',
            padding: '5px',
            background: '#444',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            zIndex: 99999,
          }}>{`${isReactQueryDevToolsOpen ? 'Hide' : 'Show'} ReactQuery devtools`}</button>
      )}

      {isReactQueryDevToolsOpen && (
        <ReactQueryDevtoolsPanel
          style={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            zIndex: 9999,
          }}
          onClose={() => setIsReactQueryDevToolsOpen(false)}
        />
      )}

      <button
        type="button"
        onClick={() => setIsAppDevToolsOpen(!isAppDevToolsOpen)}
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          padding: '5px',
          background: '#444',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          zIndex: 99999,
        }}>
        {isAppDevToolsOpen ? 'Hide DevTools' : 'Show DevTools'}
      </button>

      {isAppDevToolsOpen && (
        <div
          style={{
            position: 'fixed',
            bottom: '45px',
            right: '10px',
            padding: '10px',
            background: 'rgba(0, 0, 0, 1)',
            color: 'white',
            borderRadius: '4px',
            border: '1px solid #fff',
            zIndex: 9999,
            height: '500px',
            overflow: 'auto',
            width: '300px',
          }}>
          <h4
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              margin: '0 0 12px 0',
            }}>
            DevTools
          </h4>
          <div style={{ marginBottom: '10px' }}>
            Environment: {process.env.NODE_ENV}
          </div>
          <SessionReplay />
          <ContentLock />
          <FeatureFlags />
        </div>
      )}
    </>
  )
}

export function DevTools() {
  return (
    <ErrorBoundary fallback={null}>
      <DevToolsPanel />
    </ErrorBoundary>
  )
}
